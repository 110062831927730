import clx from '../../utils/clx';
// @ts-ignore
import logo from './wander-logo.svg';

type Props = {
  invert?: boolean;
  width?: number;
  height?: number;
};

const Logo = ({ invert = false, width = 128, height = 30 }: Props) => (
  <img
    src={logo.src}
    alt='Wander'
    className={clx('transition-[filter] duration-300', invert && 'invert')}
    width={128}
    height={30}
    style={{
      width: `${width}px`,
      height: `${height}px`,
    }}
  />
);

export default Logo;
